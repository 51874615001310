.BRNDTS_default_content {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #26e293, #024ab6);

  .box {
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 8px;
    border: 3px solid whitesmoke;
    border-radius: 5px;
    padding: 8px;
  }

  .icon {
    flex: 1;

    svg {
      max-height: 45px;
    }
  }
}
