#BRNDTS {
  position: relative;
  padding-top: 0;
  height: 0;
  max-width: 100%;
  overflow: hidden;
  width: 100%;
  padding-bottom: 56.25%;

  &[data-brndts_control="brndts_fs"] {
    video::-webkit-media-controls-fullscreen-button {
      display: none;
    }
  }

  &[data-fullscreen="true"] {
    video {
      height: 100vh !important;
    }
  }

  &[data-fullscreen_fb="true"] {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 10000000;
    background: #000;
    border-radius: 0 !important;
    height: 100%;
    margin: 0;
    width: 100%;

    video {
      height: 100%;
    }
  }
}
