#BRNDTS_CTLTB {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 0;
  height: 60px;
  width: 100%;
  pointer-events: none;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 12px;
  padding-right: 12px;
}

[data-debug="true"] {
  #BRNDTS_CTLTB {
    outline: 1px solid purple; /* Set the outline */
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
    &::before {
      opacity: 0.5;
      content: attr(id);
      position: absolute;
      height: 16px;
      font-size: 8px;
      top: 0px;
      left: 0;
      background-color: purple;
      padding: 4px 4px;
      border-radius: 4px;
      color: whitesmoke;
    }
  }
}
