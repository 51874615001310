[data-debug="true"] {
  .BRNDTS_R {
    outline: 1px solid red; /* Set the outline */
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
    &::before {
      opacity: 0.5;
      content: attr(id);
      position: absolute;
      height: 16px;
      font-size: 8px;
      top: -16px;
      left: 0;
      background-color: red;
      padding: 4px 4px;
      border-radius: 4px;
    }
  }
}

.BRNDTS_R {
  position: absolute;
  pointer-events: all;

  .BRNDTS_RB {
    position: relative;
    // transition: width 0.05s ease-in-out, height 0.05s ease-in-out;

    .BRNDTS_RB_SLIDE_IN {
      animation: slide_in_from_right 1s forwards;
    }

    .BRNDTS_RB_SLIDE_OUT {
      animation: slide_out_to_left 1s ease-in-out;
    }
  }

  .BRNDTS_RM {
    display: none;
    // transition: width 0.05s ease-in-out, height 0.05s ease-in-out;
    pointer-events: none;
  }
}

.BRNDTS_PLANE {
  .PLANE_POINT {
    position: absolute;
    width: 5px;
    height: 5px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
}
