// https://codepen.io/burtclan/pen/PoOOey

// @mixin BRNDTS_FTH_B($offset, $blur-radius, $spread-radius) {
//   &.BRNDTS_FTH-left:after {
//     box-shadow: inset $offset 0 $blur-radius (-$spread-radius);
//   }

//   &.BRNDTS_FTH-right:after {
//     box-shadow: inset (-$offset) 0 $blur-radius (-$spread-radius);
//   }

//   &.BRNDTS_FTH-top:after {
//     box-shadow: inset 0 $offset $blur-radius (-$spread-radius);
//   }

//   &.BRNDTS_FTH-bottom:after {
//     box-shadow: inset 0 (-$offset) $blur-radius (-$spread-radius);
//   }

//   &.BRNDTS_FTH-left.BRNDTS_FTH-right:after {
//     box-shadow: inset $offset 0 $blur-radius (-$spread-radius),
//       inset (-$offset) 0 $blur-radius (-$spread-radius);
//   }

//   &.BRNDTS_FTH-left.BRNDTS_FTH-top:after {
//     box-shadow: inset $offset ($offset) $blur-radius (-$spread-radius);
//   }

//   &.BRNDTS_FTH-right.BRNDTS_FTH-bottom:after {
//     box-shadow: inset (-$offset) (-$offset) $blur-radius (-$spread-radius);
//   }

//   &.BRNDTS_FTH-top.BRNDTS_FTH-right:after {
//     box-shadow: inset (-$offset) $offset $blur-radius (-$spread-radius);
//   }

//   &.BRNDTS_FTH-top.BRNDTS_FTH-bottom:after {
//     box-shadow: inset 0 $offset $blur-radius (-$spread-radius),
//       inset 0 (-$offset) $blur-radius (-$spread-radius);
//   }

//   &.BRNDTS_FTH-left.BRNDTS_FTH-bottom:after {
//     box-shadow: inset $offset (-$offset) $blur-radius (-$spread-radius);
//   }

//   &.BRNDTS_FTH-top.BRNDTS_FTH-right.BRNDTS_FTH-bottom:after {
//     box-shadow: inset (-$offset/2) 0 $blur-radius $spread-radius;
//   }

//   &.BRNDTS_FTH-left.BRNDTS_FTH-right.BRNDTS_FTH-bottom:after {
//     box-shadow: inset 0 (-$offset/2) $blur-radius $spread-radius;
//   }

//   &.BRNDTS_FTH-left.BRNDTS_FTH-top.BRNDTS_FTH-bottom:after {
//     box-shadow: inset ($offset/2) 0 $blur-radius $spread-radius;
//   }

//   &.BRNDTS_FTH-left.BRNDTS_FTH-top.BRNDTS_FTH-right:after {
//     box-shadow: inset 0 ($offset/2) $blur-radius $spread-radius;
//   }

//   &.BRNDTS_FTH-ALL:after,
//   &.BRNDTS_FTH-left.BRNDTS_FTH-top.BRNDTS_FTH-right.BRNDTS_FTH-bottom:after {
//     box-shadow: inset 0 0 $blur-radius $spread-radius;
//   }
// }

// $BRNDTS_FTH-offset: 40px !default;
// $BRNDTS_FTH-blur-radius: 40px !default;
// $BRNDTS_FTH-spread-radius: 16px !default;

// $BRNDTS_FTH-narrow-offset: 13px !default;
// $BRNDTS_FTH-narrow-blur-radius: 13px !default;
// $BRNDTS_FTH-narrow-spread-radius: 13px !default;

// $BRNDTS_FTH-wide-offset: 80px !default;
// $BRNDTS_FTH-wide-blur-radius: 80px !default;
// $BRNDTS_FTH-wide-spread-radius: 28px !default;

// .BRNDTS_FTH_B {
//   position: relative;
//   display: inline-block;
//   color: rgba(0, 0, 0, 0.5);

//   &:after {
//     content: "";
//     position: absolute;
//     display: block;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     border-collapse: separate;
//   }

//   @include BRNDTS_FTH_B(
//     $BRNDTS_FTH-offset,
//     $BRNDTS_FTH-blur-radius,
//     $BRNDTS_FTH-spread-radius
//   );

//   &.FTH-NARROW {
//     @include BRNDTS_FTH_B(
//       $BRNDTS_FTH-narrow-offset,
//       $BRNDTS_FTH-narrow-blur-radius,
//       $BRNDTS_FTH-narrow-spread-radius
//     );
//   }

//   &.FTH-WIDE {
//     @include BRNDTS_FTH_B(
//       $BRNDTS_FTH-wide-offset,
//       $BRNDTS_FTH-wide-blur-radius,
//       $BRNDTS_FTH-wide-spread-radius
//     );
//   }
// }

.BRNDTS_R_FTH {
  mask-image: linear-gradient(
      180deg,
      transparent 0%,
      black 10%,
      black 90%,
      transparent 100%
    ),
    linear-gradient(
      90deg,
      transparent 0%,
      black 10%,
      black 90%,
      transparent 100%
    );
  mask-composite: intersect;
}

.BRNDTS_R_B {
  border-width: 2px;
  border-style: solid;
  //border-image: linear-gradient(to top, #26e29483 0%, #024ab67a 100%) 1;
  border-image: linear-gradient(135deg, #26e29483, #024ab67a) 1;
  box-shadow: 0px 4px 4px rgba(30, 30, 30, 0.25);
  border-radius: 5px;
}
