#BRNDTS_CTLBB {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  bottom: 0;
  height: 43px;
  width: 100%;
  pointer-events: none;
  padding-top: 3px;
  padding-bottom: 0px;
  padding-left: 12px;
  padding-right: 12px;
}
