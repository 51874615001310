@keyframes slide-in-rtl {
  0% {
    opacity: 0;
    transform: translate(100%, 0);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes slide-out-ltr {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(100%, 0);
  }
}

@keyframes slide-in-btt {
  0% {
    opacity: 0;
    transform: translate(0, 100%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes slide-out-ttb {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 100%);
  }
}

@keyframes slide-in-ttb {
  0% {
    opacity: 0;
    transform: translate(0, -100%);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes slide-out-btt {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, -100%);
  }
}

.ANIMATE {
  animation-duration: 0.5s;
  animation-delay: 0.5s;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;

  &[data-animation="SLIDE_IN_TOP_RIGHT"] {
    animation-delay: 0s;
    animation-name: slide-in-rtl;
  }
  &[data-animation="SLIDE_OUT_TOP_RIGHT"] {
    animation-fill-mode: forwards;
    animation-name: slide-out-ltr;
  }
  &[data-animation="SLIDE_IN_BOTTOM_RIGHT"] {
    animation-delay: 0s;
    animation-name: slide-in-btt;
  }
  &[data-animation="SLIDE_OUT_BOTTOM_RIGHT"] {
    animation-fill-mode: forwards;
    animation-name: slide-out-ttb;
  }
  &[data-animation="SLIDE_IN_TOP_CENTER"] {
    animation-delay: 0s;
    animation-name: slide-in-ttb;
  }
  &[data-animation="SLIDE_OUT_TOP_CENTER"] {
    animation-fill-mode: forwards;
    animation-name: slide-out-btt;
  }
}
