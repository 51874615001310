.BRNDTS_BTN {
  border: 0;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  pointer-events: all;
  color: whitesmoke;

  &[data-state="pressed"] {
    background-color: transparent;
  }

  &:hover {
    background-color: transparent;
    border: 0;
    outline: none;
    box-shadow: none;
    border-color: transparent;
  }
}

.BRNDTS_BTN_FS_TOP {
  padding-left: 8px;
  padding-right: 8px;
}

.BRNDTS_BTN_FS_BOT {
  width: 40px;
}
