.BRNDTS_PRGS_I {
  width: 100%;
  height: 100%;
  position: relative;
  pointer-events: all;
  .BRNDTS_PRGS_R {
    height: 100%;
    position: absolute;
  }
}

.BRNDTS_PRGS {
  width: 100%;
  height: 4px;
  background-color: #626060d9;
  position: relative;
  border-radius: 2px;
  overflow: hidden;
  margin: 0 4px;
  align-self: flex-end;
}
