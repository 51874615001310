[data-debug="true"] {
  #BRNDTS_ARW {
    outline: 1px solid green; /* Set the outline */
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
    &::before {
      opacity: 0.5;
      content: attr(id);
      position: absolute;
      height: 16px;
      font-size: 8px;
      top: -16px;
      left: 0;
      background-color: green;
      padding: 4px 4px;
      border-radius: 4px;
    }
  }

  .BRNDTS_AR {
    outline: 1px solid red; /* Set the outline */
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.3);
    &::before {
      opacity: 0.5;
      content: attr(id);
      position: absolute;
      height: 16px;
      font-size: 8px;
      top: -16px;
      left: 0;
      background-color: red;
      padding: 4px 4px;
      border-radius: 4px;
    }
  }
}
